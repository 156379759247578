::-webkit-scrollbar-track {
    background-color: #dcdcdc;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #dcdcdc;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 6px;
  }
  .header {
    margin-bottom: 1rem;
  }
  .content {
    margin-top: 1rem;
  }
  .btn-videodoc-green {
    color: #fff;
    background-color: #0085cf;
    border-color: 0085CF;
  }
  .btn-videodoc-green-outline {
    color: #0085cf;
    border-color: #0085cf;
  }
  .btn-videodoc-green-outline:hover,
  .btn-videodoc-green-outline:active,
  .btn-videodoc-green-outline:focus {
    color: #fff;
    background-color: #0085cf;
    border-color: #0085cf;
  }
  .btn-videodoc-green:hover,
  .btn-videodoc-green:active,
  .btn-videodoc-green:focus {
    opacity: 0.8;
    color: #fff;
  }

  .sidebar-brand{
    position: sticky;
    top: 0;
    z-index: 1111;
  }
  .sidebar-brand,
  .sidebar-brand:hover {
    background: #ffffff;
    text-align: center;
  }
  .sidebar-link:active {
    text-decoration: none;
    background: #e3eeff;
  }
  .sidebar-link:hover {
    text-decoration: none;
  }
  .sidebar-brand img {
    height: 36px;
  }
  .table thead th {
    border-top: none;
    font-size: 14px;
  }

  .table tbody tr td {
    font-size: 14px;
  }

  .inlined {
    display: inline;
  }
  .physicians_table .dropdown-toggle,
  .patients_table .dropdown-toggle {
    background: #007bff;
    border-color: #007bff;
  }
  .patients_table .page-item.active .page-link,
  .physicians_table .page-item.active .page-link {
    background: #007bff;
    border-color: #007bff;
  }
  .wrapper:before {
    background: #f0f0f0;
    height: 100% !important;
  }
  
  .sidebar-link svg .sidebar-link .fas,
  .sidebar-link .far,
  .sidebar-link .fal,
  .sidebar-link .fab,
  a.sidebar-link svg,
  a.sidebar-link .fas,
  a.sidebar-link .far,
  a.sidebar-link .fal,
  a.sidebar-link .fab {
    color: #c8ecf9;
  }
  
  .splash .splash-icon,
  .bg-primary-dark {
    background: #3c9fbd !important;
  }
  .table-expander {
    background: #2e855c1a;
    padding: 15px;
    border-radius: 5px;
  }
  .table-expander p:last-child {
    margin-bottom: 0px;
  }
  .spinner-border {
    display: block;
    width: 2rem;
    left: 48%;
    z-index: 999;
    top: 48%;
    position: fixed;
  }
  .react-bootstrap-table td button {
    border-radius: 5px;
  }
  .status-none {
    display: none;
  }
  .statustoggle {
    cursor: pointer !important;
    margin: 0 0.25em;
  }
  .btn-equipo-white {
    background: #fff;
    color: whitesmoke;
  }
  .pull-right {
    float: right;
  }
  .coupon_input .react-datepicker-wrapper {
    display: block;
  }
  .coupon_input .react-datepicker-wrapper input {
    display: block;
    width: 100%;
    height: calc(1.90625rem + 2px);
    padding: 0.25rem 0.7rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .view-more {
    display: inline-block;
    margin-bottom: 0px;
    margin-right: 15px;
    cursor: pointer;
    background-color: #dff7ee;
    padding: 2px 10px;
    border-radius: 1em;
    justify-content: center;
    text-align: center;
    font-weight: 500;
  }
  
  .view-more:hover {
    background-color: #0085cf;
    color: white;
    transition: 0.3s ease-out;
  }
  
  .view-more:active {
    background-color: #dff7ee;
  }
  
  .table-status {
    color: #fff;
    padding: 2px 10px;
    border-radius: 3px;
    font-weight: 500;
    width: 6.25em !important;
    white-space: nowrap;
  }
  
  .nostatus {
    background: #d078f3;
    color: #2e855c;
    border: 1px solid #2e855c;
  }
  
  .bg-completed {
    background: #6499d2;
  }
  
  .bg-scheduled {
    background: #38c485;
  }
  
  .bg-inprogress {
    background: #efb918;
  }
  
  .bg-cancelled {
    background: #f35c51;
  }
  
  .bg-no-show {
    background: #a1a1a1;
  }
  
  .equipo-logo {
    margin: auto;
    width: 27%;
  }
  
  .powered-by {
    font-size: 10px;
    margin: 0 0 0 7px;
    color: grey;
  }
  
  .separator-pipe {
    padding: 0 0.5em;
  }
  
  .view-button {
    color: #428bca;
    cursor: pointer !important;
  }
  
  .view-button:hover {
    text-decoration: underline;
  }
  
  .card-tooltip {
    display: none;
    z-index: 9999;
  }
  .download-attachments {
    flex-basis: auto;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .download-attachments p {
    margin-bottom: 0px;
  }
  .download-attachments svg {
    margin-right: 5px;
  }
  .download-attachments button {
    color: #00875e;
    background: #fff;
    border-color: #00875e;
  }
  .download-attachments button:hover,
  .download-attachments button:active,
  .download-attachments button:focus,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background: #0085cf;
    border-color: #0085cf;
  }
  
  .change-group-button {
    background-color: #2d7c9d;
    color: white;
    padding: 0.25em 0.5em;
    border-radius: 3px;
    display: inline-block;
    margin: 0.5em 0 0 0.2em;
    cursor: pointer;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    width: 4.7em;
  }
  
  .group-selector {
    width: 13em;
  }
  
  .pharmacy-selector {
    width: 28em;
  }
  
  .group-selector-table-spacer {
    height: 1em;
  }
  
  .Add-btn {
    background-color: rgb(7, 152, 209) !important;
    border-color: rgb(34, 184, 243);
  }
  
  .Add-btn-referral {
    background-color: rgb(7, 152, 209) !important;
    border-color: rgb(34, 184, 243);
    width: 150px;
    position: absolute;
    right: 0.2em;
    top: 0em;
  }
  .Add-btn-permission-manager {
    background-color: rgb(7, 152, 209) !important;
    border-color: rgb(34, 184, 243);
    width: 130px;
    position: absolute;
    right: 1.2em;
  }
  .table-button {
    padding: 2rem 20rem 5rem 6rem;
    width: 100px;
    height: 2.1em;
    background-color: #0085cf !important;
  }
  .table-button:hover {
    background-color: #1d95d6 !important;
  }
  .admin-users-list {
    margin-top: 0.1em;
  }
  
  .admin-users-list tbody {
    display: block;
    height: 32.5vh;
    overflow-y: hidden;
  }
  
  .admin-users-list thead {
    display: table;
    width: calc(100% - 6px);
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .admin-users-list thead {
      width: calc(100% - 18px);
    }
  }
  @supports (-moz-osx-font-smoothing: auto) {
    .admin-users-list thead {
      width: calc(100% - 6px);
    }
  }
  
  .admin-users-list tbody tr {
    display: table;
    width: 99.93%;
    table-layout: fixed;
  }
  
  /* .react-bootstrap-table { */
    /* height: 430px; */
  /* } */
  .appUserptlist .react-bootstrap-table-pagination .dropdown .dropdown-menu {
    bottom: 100% !important;
    top: auto !important;
    left: 0 !important;
    right: auto !important;
  }
  .groups-search .c-search-field{
      padding-right: 90px;
  }
  .supported-insurace-search .c-search-field{
      padding-right: 293px;
  }
    .groups-search-ordertype .c-search-field{
      padding-right: 290px;
  }
  .sqlquery .sqlquery-table thead {
      position: sticky;
      background-color: #fff;
      top: 0;
  }
  @-moz-document url-prefix() {
    .sqlquery .sqlquery-table thead {
      position: sticky;
      background-color: #fff;
      top: 0;
    }
  }
  @supports (-moz-osx-font-smoothing: auto) {
    .sqlquery .sqlquery-table thead {
      position: sticky;
      background-color: #fff;
      top: 0;
    }
  }
  .aclog-datepicker svg {
    float: right;
  }
  
  /* .plhldr {
     &:focus {
      background-color: yellow;
    }}
   */
  
  .leaflet-container {
    width: 100%;
    height: 80vh;
  }
  
.ht-560{
  height: 560px;
  overflow: auto;
}

.role-head-style{
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #74d3da;
}
  /* .facility-map{
          width:1080px;
          height:720px;
        } */
  
  /*  
   .plhldr 
   {
      
          color: hsl(0, 0%, 20%);
  
  
   
   }
  
  
   .plhldr::placeholderText {
      color: white;
      opacity: 1;
    }
          */
  
  /* .plhldr::placeholderText {
      color: blue;
  }
   */
  
  /* .hov:hover{
      
          background-color: yellow;
        
  } */
  .copycontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
  .copy-area {
    display: flex;
    align-items: center;
  }
  
  input {
    /* margin: 1rem; */
    padding: 0.3rem;
  }
  
  button {
    letter-spacing: 1px;
    padding: 0.3rem;
  }
  
  .copy-feedback {
    margin-left: 1rem;
    visibility: hidden;
  }
  
  .copy-feedback.active {
    visibility: visible;
  }
  
  @media screen and (max-width: 600px) {
    .container,
    .copy-area {
      flex-direction: column;
    }
  
    .copy-feedback {
      display: block;
      margin-top: 0.5rem;
    }
  }
  
  .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1pxrgba (0, 0, 0, 0.05);
  }
  .form-control {
    margin: 0;
  }
  
  .money-button-blue {
    background: none;
    border: none;
  }
  
  .money-button-blue:hover {
    background: none !important;
    border: none !important;
  }
  
  .money-button-blue:focus {
    border: none;
    background: none !important;
    box-shadow: none !important;
  }
  
  .money-button-blue svg {
    color: #3f86fa;
  }
  
  #react-select-1-listbox {
      z-index: 1111;
    }
  
  #react-select-2-listbox {
    z-index: 1111;
  }
  
  #react-select-3-listbox {
      z-index: 1111;
    }
  
    #react-select-4-listbox {
      z-index: 1111;
    }

    #react-select-5-listbox {
      z-index: 1111;
    }
  

    #react-select-6-listbox {
      z-index: 1111;
    }
  
  
  .css-26l3qy-menu{
      z-index: 1111;
  }
  
  #npi{
      height: 37px;
  }
  .panel {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
       border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%); 
      box-shadow: 0 1px 1pxrgba(0,0,0,.05);
  }
  
  .css-1s2u09g-control{
      height: 35px;
      width: auto;
      overflow: hidden;
  }
  
  .mrg-top-27{
      margin-top: 27px !important;
  }
  
  .mrg-10-10-10-0{
    margin: 10px 10px 10px 0 !important;
}

.mrg-10-0{
    margin: 10px 0 !important;
}

.mrg-btm-15{
  margin-bottom: 15px;
}

.css-1s2u09g-control{
  height: 38px;
  overflow: hidden;
}

.close:focus {
  outline: none !important;
}

.role-list-head-custom{
  background: rgb(230, 230, 230);
  float: right;
  width: 98%;
  padding: 5px;
  border-radius: 0.3rem;
}

@media only screen and (max-width:991px) {
  .role-list-head-custom{
    width: 97%;
  }
}

.css-v7mgfm{
  margin: 5px !important;
}
#providermodal .modal-content{
  width:65%;
  margin:0 auto;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mrg-top-30{
  margin-top: 30px!important;

}

.pad-rt-0{
  padding-right: 0 !important;
}

.mrg-btm-22{
  margin-bottom: 22px;
}

.viewPermissionsForAppUsersModal .react-bootstrap-table{
  height: 255px;
  overflow: auto;
  margin-top: 15px;
  border-top: 2px solid #dee2e6;
}

.viewPermissionsForAppUsersModal .react-bootstrap-table:nth-child(2){
  margin-top: 45px !important;
}

.viewPermissionsForAppUsersModal .react-bootstrap-table table{
  margin-top: 0 !important;
}

.viewPermissionsForAppUsersModal .react-bootstrap-table thead{
  position: sticky;
  top: 0;
  background-color: #fff;
}

.css-gh35ha{
  margin: 5px 0;
}


.testSelect__menu {
  box-shadow: 5px 5px 15px -5px #ccc, -5px 5px 15px -5px #ccc !important;
  z-index: 10 !important;
}

.program-tick-minus button{
  margin: 0 10px;
}

.program-tick-minus .tick-button{
  background-color: #28a745;
  border: #28a745;
}

.program-tick-minus .minus-button{
  background-color: #e12c2c;
  border: #e12c2c;
}

.care-roaster-table-align .table-position{
  margin-top: 35px !important;
}

.pandadoc-select div{
  height: 32px !important;
  min-height: 32px;
  align-items: center;
}

.ht-38{
  height: 38px;
}

.card-header-sticky{
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.top-0{
  top:0;
}

.right-0{
  right: 0;
}

/* .ovreflow-revert .css-9jq23d{
  overflow: revert !important;
} */

/* .new-admin-user-modal #react-select-4-listbox{
  height: 150px;
  overflow-y: auto;
} */

.email-digest-button {
  position: relative;
}

.email-digest-button button {
  background-color: #F8F8F8;
  border: none;
  color: #333333;
  font-family: sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  text-align: left;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.email-digest-button button:hover {
  background-color: #EEEEEE;
}

.email-digest-button .arrow {
  margin-left: 10px;
}

.email-digest-button .arrow.up {
  transform: rotate(180deg);
}

.email-digest-options {
  position: absolute;
  top: 80px; /* adjust this value to move the dropdown further down the page */
  width: 200px;
  background-color: #f8f8f8;
  border: none;
  color: #333333;
  font-family: sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}


.email-digest-options .option {
  background-color: #F8F8F8;
  border: none;
  color: #333333;
  font-family: sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.email-digest-options .option:hover {
  background-color: #EEEEEE;
}

.email-digest-options .option.enable {
  color: green;
}

.email-digest-options .option.disable {
  color: red;
}
.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-export {
  margin-right: 10px;
}

/* .move-to-group .modal-body{
  position: revert;
}

.move-to-group .modal-body #react-select-4-listbox{
  height: 30vh;
} */
.ref-preference-grp-pad{
  padding: 0.75rem 0rem 0rem 2rem !important;
}

.referral-category-configuration .viewtype-input {
  padding: 0 0 0 20px;
  margin: 5px 0;
}

.referral-category-configuration .bottom-space{
  margin-bottom: 20px;
}
.visually-hidden {
  visibility: hidden;
}

.emitable .react-bootstrap-table{
  overflow: auto;
}
.emitable .react-bootstrap-table table {
  table-layout: auto;
}

.emitable .row.react-bootstrap-table-pagination {
    margin-top: 1rem;
}
.providerTable .react-bootstrap-table{
  overflow: auto;
}
.providerTable .react-bootstrap-table table {
  table-layout: auto;
}

.providerTable .row.react-bootstrap-table-pagination {
    margin-top: 1rem;
}
.hl7table .react-bootstrap-table{
  overflow: auto;
}
.hl7table .react-bootstrap-table table {
  table-layout: auto;
}

.hl7table .row.react-bootstrap-table-pagination {
    margin-top: 1rem;
}

.custom-date-range{
  position: absolute;
  z-index: 9999;
  display: flex;
    padding: 0;
}
.custom-date-range-selections{
    z-index: 9999;
    background: #fff;
    border: 1px solid #D7D7D7;
    height: 270px;
}
.custom-date-range-selections ul{
  list-style: none;
  padding: 0 15px;
  width: 150px;
}
.custom-date-range-selections li{
  color: #0189D8;
  padding: 8px 0 0;
  cursor: pointer;
}
.custom-date-range .react-datepicker__header {
  border: none;
    background: none;
    padding: 12px;
}
.custom-date-range .react-datepicker {
  border-left: 1px solid #ECECEC;
  border-radius: 0;
}
.custom-date-range .react-datepicker__month-container {
  border: 1px solid #DADADA;
}

.input-container {
  position: relative; /* Ensure the container is positioned relatively */
}

.input-container input[type='text'] {
  padding-right: 30px; /* Adjust the padding to make space for the icon */
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the icon doesn't interfere with input interactions */
}
.filter-button > div{
  margin-right: 10px;
}
.rdtPicker td, .rdtPicker th{
 padding: 0 !important; 
}
.rrt-middle-container{
  min-height: 70px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.leaflet-container {
  z-index: 0 !important;
}
