/* .providerDetailsCard {
  padding: 1.563rem 1.188rem;
} */
.detailsProfImg {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.detailsProfImg > img {
  width: 100px;
  height: 100px;
}
.consultation_fee{
  float: right;
}

.consultation_fee strong{
  color: #2d9c6a;
}


.detailsProfContent,
.detailsBtn {
  padding-left: 0.5rem;
}

.detailsProfContentStarRating div{
  line-height: normal;
  display: inline-block;
  color: #fff;
  padding: 2px 4px 2px 6px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  vertical-align: middle;
  background-color: #388e3c;
  margin-left: 10px;
}

.detailsProfContentStarRating .iconLeftSpacing {
  margin-left: 5px !important;
}

.detailsBtn {
  margin-top: 1rem;
}

.detailProfRow {
  padding-bottom: 1rem;
}

.detailProfLabel {
  text-align: right;
}


@media screen and (max-width: 991px) {
  .detailProfLabel {
    justify-content: flex-start;
  }
  .detailsProfImg > img {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 768px) {
  .detailsProfContent {
    margin-top: 1rem;
  }
  .detailProfLabel {
    text-align: left;
  }
}

.fee_card{
  border: 2px solid #38c485;
  height: 70px;
  width: 100%;
  border-bottom-right-radius: 5px;
  position: relative;
  border-bottom-left-radius: 5px;
}

.fee_card_content_label{
  text-align: center;
  padding: 2px 0px;
  color: #fff;
  font-size: 18px;
  border-top-right-radius: 5px;
  background: #38c485;
  border-top-left-radius: 5px;
  border-bottom: none!important;
  font-weight: bold;
  border: 2px solid #38c485;
}
.fee_card_content {
  text-align: center;
  transform: translate(0, 10px);
  width: 100%;
  position: absolute;
}
.fee_card_content h4{
  font-size: 30px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 0px;
}
.fee_card_content p{
  font-size: 16px;
  color: #ffa500;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 0px;
}

.consult_fee_green_box{
  color: #fff;
    font-size: 14.6px;
    background: #38c485;
    border-bottom: none!important;
    font-weight: bold;
    margin-bottom: 3px;
}