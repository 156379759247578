.date-filter-component {
  /* margin-top: 1em; */
}

.go-buttons {
  margin: 2em 0.5em 0em 0em;
  width: 63px;
  height: 2.1em;
  background-color: #0085CF !important;
  border: none;
}

.group-search-button {
  margin: 0.145em 0.5em 0em 0em;
  width: 100px;
  height: 2.1em;
  background-color: #0085CF !important;
  border: none;
}

.scan-btn{
  text-align: center;  
  display: flex;
  position: relative; 
  background-color: #0085CF !important;
  
}
.go-button-sql {
 
  background-color: #0085CF !important;

}

/* .group-filter-clear-button {
  margin: 2em 0.5em 0.5em 0.5em;
  width: 100px;
  height: 2.1em;
  background-color: #6b757c !important;
  border: none;
  
} */

.export-button {
  margin-top: -0.5rem;

}

.patient-export{
  margin-top: -37px !important;
  margin-right: -10px;
}

.c-pass-label{
  width: max-content;

}

.export-referral{
  margin-left: -51px;
}

.btn-export{
  color: #fff;
  background-color: #ffd042  !important;
  border-color: #ffbf00 !important;

}


@media screen and (min-width: 1280px) and (max-width: 1295px) {
  .export-referral {
    margin-left: -49px;
  }
}

.appointment-list {
  margin-top: -2em;
  padding-bottom: 4em;
}

.appointment-list-view-btn {
  background-color: white;
  border: 2px solid #00875e;
  color: #3f4674;
}

.appointment-table-expanded {
  background-color: #e0f8ee;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 0.25em 0.25em 0em 0.7em;
  margin: 0.25em;
}

.appointment-table-expantion-area {
  width: 98%;
  margin: auto;
}

.search-box {
  position: absolute;
  width: inherit;
  top: 0em;
  right: 5em;
}

.search-box-new {
  /* width: 25%;
  float: right !important;
  margin-right: 55px; */
}

.clear-search-box {
  background-color: #e0f8ee;
  border: 1px solid #3B7DDD;
  color: #3e4676;
}

.separator {
  height: 2px;
  background-color:#def3ff;
  margin: 0.5em auto;
}

.eye-button {
  border-color: #00875e;
  color: #00875e;
  margin-left: 5px;
}

.eye-button:hover {
  border-color: white;
  background-color: #00875e;
  color: white;
}

.money-button {
  border-color: #3f86fa;
  color: #3f86fa;
  margin-left: 0.3em;
}

.money-button:hover {
  border-color: white;
  background-color: #3f86fa;
  color: white;
}

.money-buttons {
  border-color: white;
  background-color: #3f86fa;
  color: white;
  margin-left: 0.3em;
}

.money-buttons:hover {
  border-color: white;
  background-color: #3f86fa;
  color: white;
}

.delete-btn {
  border-color: #f34134;
  color: #f34134;
  margin-left: 0.3em;
}

.delete-btn:hover {
  border-color: white;
  background-color: #f34134;
  color: white;
}



.second-row-filters {
  padding-top: 1em;
}

.number-of-records {
  margin: 0.6em 0 -0.7em 0;
}

.patient-record{
  margin-top: 50px ;
}

.patient-new-table{
  margin-top: -51px;
}

.patient-table-only{
  margin-top: 20px;
}

.blank-state {
  text-align: center;
  margin-top: 1em;
}

/* .appointment-list tbody {
    display: block;
    height: 31vh;
    overflow-y: scroll;
  }
  
  .appointment-list thead {
    display: table;
    width: 98.7%;
    table-layout: fixed;
  }
  
  .appointment-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  } */
.patient-list {
  margin-top: -2em;
}

.group-list-tbody-height tbody {
  display: block;
  min-height: 100vh;
  max-height: 41vh;
  overflow-x: hidden;
  overflow-y:scroll;
}

.patient-list tbody {
  display: block;
  min-height: 10vh;
  max-height: 45vh;
  overflow-x: hidden;
  overflow-y:scroll;
}
.table-list-height tbody {
  display: block;
  min-height: 10vh;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y:auto;
}

.overflow-modal{
  min-height: 40vh !important;
}

.group-list-referral {
 /* overflow-y: hidden !important; */
 word-break: break-word;
 display: block;
 /* max-height: 90vw; */
}

/* .group-list-referral thead tr th:nth-child(1) {
  width: 140px !important

} */

.Select-menu-outer{top: auto; bottom: 100%}


.patient-list thead {
  display: table;
  width: calc(100% - 6px);
  table-layout: fixed;
}
@-moz-document url-prefix() {
  .patient-list thead{
      width: calc(100% - 18px);
  }
}
@supports (-moz-osx-font-smoothing: auto) {
  .patient-list thead{
      width: calc(100% - 6px);
  }
}

.patient-list tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.button-width {
  height: 2.1em;
  min-width: 70px;
  max-width: auto;
  font-size: 1em;
  padding: 0.5em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.yes-button {
  background-color: #0085CF !important;
  border: 1px solid #0085CF !important;
  padding: 0 0.5em;
}

.yes-button:disabled {
  background-color: #7D978F !important;
  border: 1px solid #7D978F !important;
  padding: 0 0.5em;
}

.yes-button:hover {
  background-color: #006297 !important;
  border: 1px solid #015988 !important;
  padding: 0;
}
.yes-button:hover:disabled {
  background-color: #7D978F !important;
  border: 1px solid #7D978F !important;
  padding: 0 0.5em;
}

.next-button {
  background-color: #009bc8;
  border: 1px solid #009bc8;
  padding: 0;
}

.next-button:hover {
  background-color: #026988;
  border: 1px solid #026988;
  padding: 0;
}

.no-button {
  color: #15252c;
  border: 1px solid #99a0a5;
  padding: 0;
}

.close-btn-alone {
  color: white;
  background-color: #1bc269;
  border: 1px solid #20c56d;
  padding: 0;
}

.close-btn-alone:hover {
  color: rgb(79, 72, 72);
  background-color: #4dc585;
  border: 1px solid #4dc585;
  padding: 0;
}

.no-button:hover {
  background-color: #99a0a5;
  border: 1px solid #64686b;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.patient_table {
  margin-top: 37px !important;
}

.view-appt-btn {
  background-color: #00875e;
  border: none;
  color: white;
  padding: 0.30em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.view-appt-btn:hover {
  background-color: #00be85;
  border: none;
  text-decoration: none;
  color: white;
}

.cancel-appt-button-width {
  height: 2em;
  width: 100px;
  font-size: 1em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.required {
  color: red;
}



.card-password{
  margin-top: 17%;
  width: 47%;
  justify-content: center;
  margin-left: 22%;

}

.background{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1300' height='700' preserveAspectRatio='none' viewBox='0 0 1300 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1247%26quot%3b)' fill='none'%3e%3crect width='1300' height='700' x='0' y='0' fill='rgba(149%2c 221%2c 227%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c454.024C114.214%2c455.367%2c230.798%2c570.04%2c330.327%2c513.998C428.525%2c458.705%2c396.858%2c305.115%2c445.226%2c203.328C494.673%2c99.27%2c614.642%2c26.521%2c616.709%2c-88.669C618.821%2c-206.377%2c533.812%2c-306.781%2c455.965%2c-395.096C378.612%2c-482.851%2c290.345%2c-583.467%2c173.63%2c-591.33C58.347%2c-599.097%2c-20.356%2c-477.524%2c-127.554%2c-434.409C-220.383%2c-397.073%2c-339.062%2c-425.668%2c-411.047%2c-356.174C-484.068%2c-285.68%2c-501.661%2c-173.764%2c-502.669%2c-72.273C-503.603%2c21.713%2c-444.014%2c100.332%2c-416.475%2c190.198C-383.683%2c297.206%2c-419.918%2c446.396%2c-325.372%2c506.289C-230.658%2c566.288%2c-112.111%2c452.706%2c0%2c454.024' fill='%2374d3da'%3e%3c/path%3e%3cpath d='M1300 1204.289C1394.007 1206.459 1472.917 1142.87 1553.868 1095.026 1637.962 1045.324 1742.321 1009.373 1781.325 919.814 1820.234 830.4739999999999 1772.671 730.382 1754.358 634.673 1736.939 543.634 1733.105 447.131 1676.7939999999999 373.506 1618.843 297.737 1530.022 254.80399999999997 1439.904 223.531 1346.295 191.04700000000003 1248.712 177.69799999999998 1150.507 190.872 1039.933 205.70600000000002 908.725 213.80399999999997 842.5989999999999 303.66 776.334 393.706 825.119 520.0509999999999 825.991 631.848 826.723 725.651 805.092 822.967 847.302 906.74 888.884 989.267 976.328 1032.723 1053.552 1083.48 1131.585 1134.77 1206.645 1202.135 1300 1204.289' fill='%23b6e8ec'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1247'%3e%3crect width='1440' height='718' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");

}

/* .body-width{
  max-width: 75%;
} */

/* .datepicker-wrapper .react-datepicker__input-container input,
.search-wrapper .input-wrapper .react-datepicker__input-container:focus input,
.search-wrapper .input-wrapper .form-control,
.search-wrapper .input-wrapper .form-control:focus,
.search-wrapper .react-select-container .react-select__control {
  border-color: transparent !important;
  background-color: transparent !important;
} */

/* .datepicker-wrapper,
.input-wrapper,
.select-wrapper {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  border-right: 0;
  padding: 0.125rem 0.125rem 0.125rem 0.5rem;
}

.input-wrapper {
  border-left: 1px solid #ced4da;
}

.datepicker-wrapper {
  border-right: 1px solid #ced4da;
} */

/* .react-datepicker__input-container input {
  outline: none !important;
  color: #3e4676;
} */

.min-width{
  word-wrap: break-word;
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999 ;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(0, 199, 137, 0.05);
  border-top-color: #005fdb;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  z-index: 9999;
}

.overlay-z-index{
  z-index: 99999 !important
}

.action-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reason-for-cancellation {
  min-height: 5em;
  max-height: 5em;
}

.todays-appt-table {
  padding: 0 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.todays-appt-card {
  height: 70vh;
  margin-top: 2em;
}

.todays-appt-card-header {
  font-size: 1.3em;
  color: #000 !important;
}

.todays-appt-table tbody {
  display: block;
  height: 52vh;
  overflow-y: scroll;
}

.todays-appt-table tbody::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
  /* Chrome/Safari/Webkit */
}

.todays-appt-table tbody {
  scrollbar-width: none !important;
  /* Firefox */
  -ms-overflow-style: none !important;
  /* IE 10+ */
}

.todays-appt-table thead {
  display: table;
  width: 98.7%;
  table-layout: fixed;
}

.todays-appt-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.appo-list-table {
  margin-top: -3em;
}

.pharmacy-address-color {
  color: grey;
}

.overflow-modal {
  min-height: 30vh;
  max-height: 55vh;
  overflow: auto;
}

.pharmacy-address-textarea {
  max-height: 117px;
  min-height: 117px;
}

.Filter-address {
  min-width: 33.4%;
}

.email-button {
  margin-left: 5px;
  border-color: #00875e;
  color: #00875e;
}

.email-button:hover {
  border-color: white;
  background-color: #00875e;
  color: white;
}

.pharmacy-badge {
  font-size: 1em;
  min-width: 80px;
  max-width: auto;
}

.button-corporate {
  margin-top: 8.6%;
}

.phone-number-input {
  display: block;
  width: 100%;
  height: calc(1.90625rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-margin {
  margin-right: 10px;
}

.no-data-found-card {
  padding: 2em;
  margin: auto;
}

.advanced-search-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center;
  justify-content: center !important;
}

.advanced-search-radio-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.2em;
}

.adv-search-background-card {
  background-color: #ffffff;
  padding: 1em;
  margin-top: 39px;
  margin-right: 12px;
  margin-bottom: 26px;
  margin-left: 12px;
}
.adv-search-background-card-pt2 {
  background-color: #ffffff;
  padding: 1em;
  margin-top: 39px;
  margin-bottom: 26px;
}

@-webkit-keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.advanced-search-table {
  -webkit-animation: slideIn 1.5s ease;
  animation: slideIn 1.5s ease;
}
.patient-list-table {
  -webkit-animation: slideIn 1.5s ease;
  animation: slideIn 1.5s ease;
}

.advanced-search-table .table tr td:nth-child(1), .advanced-search-table .table tr th:nth-child(1) {
  text-align: center;
  width: 68px;
}

.advanced-search-table :nth-child(2) {
  width: 150px;
}

.advanced-search-table :nth-child(4) {
  width: 200px;
}

@media screen and (max-height: 700px) {
  .adv-search-modal-body {
    height: 450px;
  }
  .advanced-search-table-height {
    /* height: 150px; */
    max-height: 280px !important;
    overflow: scroll !important;
    word-break: normal !important;
  }
}


.advanced-search-table-height {
  /* height: 150px; */
  max-height: 280px !important;
  overflow: scroll !important;
  word-break: normal !important;
}

.medication-go-reset-btn{
  margin-top: 3%;
  
}

.radio-cntrl{
  margin-left: 3px;
}
.radio-cntrl-label{
  margin-left: 18px;
}
.radio-un-ctrl{
  margin-left: 12px;
}
.radio-un-ctrl-label{
  margin-left: 28px;
}

/* @media screen and (min-height: 900px) {
  .adv-search-modal-body {
    height: 600px;
  }
  .advanced-search-table-height {
    height: 400px;
    overflow: scroll;
    word-break: break-word;
  }
}
@media screen and (min-height: 700px) and (max-height: 900px) {
  .adv-search-modal-body {
    height: 550px;
  }
  .advanced-search-table-height {
    height: 350px;
    overflow: scroll;
    word-break: break-word;
  }
} */

.action-btn-filter{
  margin-top: -2px;
}

.toggle-button {
  margin-top: .5em;
}


.add-refund-btn{
  margin-top: -3px;
  margin-bottom: 7px;
  background-color: #38ad6f;
  border: 1px solid #38ad6f;

}

.add-refund-btn:hover{
  background-color: #00905D;
  border-color: #00905D;
  
}

.min-min-hr-font {
  font-size: 13px;
}

.centralize{
  display: flex;
  align-items: center;
  justify-content: center;
}
.rule-legend {
  background-color: #F7D159;
  height: 1em;
  width: 1em;
  margin-bottom: 3px;

}
.rule-legend-text {
  margin-left: 1.5em;
  margin-top: -21px;
  display: flex;

}

.container{
  padding: 5px;
}

.cancel-charge{
  padding-top: 5%;
  padding-bottom: 5%;
}

.next-upcoming-appointments-card {
  margin-bottom: -0.7em;
  margin-left: 11px;
  margin-right: 11px;
  margin-top: 39px;
}

.next-upcoming-appointments-card-highlight {
  background-color: #00A8D6;
  height: auto;
  width: 10px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.next-upcoming-appointments-container {
  display: flex;
}

.next-upcoming-appointments-complaint {
  white-space: nowrap;
}

.active-patient-text{
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.dob-date{
  width: 145%;
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 0.125rem 0.125rem 0.125rem 0.5rem;
    margin-left: -13px;
    height: 33px;
}

.table-headd{
  text-align: left;
  width: max-content !important;
}

.table-body{
  text-align: left;
  min-width: auto;
  width: max-content !important;
  word-wrap: break-word !important;

}


.table{
  border-collapse: collapse;
  width: auto;
  overflow-x: scroll !important;
}

.table-container { 
  display: block;
  max-width: -moz-fit-content;
  max-width: auto;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
    
}


.NPI-info-card{
  box-shadow:  0 3px 10px rgb(0 0 0 / 7%), 0 2px 5px rgb(0 0 0 / 6%);
}

.Queue-table{
color: #00A8D6;

}

.reset-buttons {
  margin: 2em 0.5em 0em 0.5em;
  width: 70px;
  height: 2.1em;
  background-color: #6b757c !important;
  border: none;
  
}
.group-reset-buttons {
  margin: 0em 0em 0.5em 0.5em;
  width: 4.125rem;
  /* height: 2.1em; */
  background-color: #6b757c !important;
  border: none;
  
}

.group-go-buttons {
  margin: 0em 0em 0.5em 0em;
  width: 4.125rem;
  /* height: 2.1em; */
  background-color: #0085CF !important;
  border: none;
}
.icd-reset-buttons {
  margin: 2.25em 0em 0em 0.5em;
  width: 4.125rem;
  /* height: 2.1em; */
  background-color: #6b757c !important;
  border: none;
  
}

.icd-go-buttons {
  margin: 2.25em 0em 0em 0em;
  width: 4.125rem;
  /* height: 2.1em; */
  background-color: #0085CF !important;
  border: none;
}
.patient-go-buttons {

  margin-top: 32px;
  width: 53px;
  /* height: 2.1em; */
  background-color: #0085CF !important;
  border: none;
}
.patient-reset-buttons {
  margin-left: 8px;
  margin-top: 32px;
  width: 76px;
  /* height: 2.1em; */
  background-color: #6b757c !important;
  border: none;
}



.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
 
}

.react-datepicker__input-container input {
  padding:0.300rem;

}

.otp-input{
  padding: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}


@media screen and (max-width: 1280px ) {
  .card-otp{
    margin-top: 17%;
    /* width: 47%; */
    justify-content: center;
    margin-left: 22%;
  }
}

@media screen and (min-width: 2488px) {
  .card-otp{
    margin-top: 17%;
    width: 47%;
    justify-content: center;
    margin-left: 22%;
    align-items: center;
  }
}

.card-otp{
  margin-top: 6%;
 /*  width: 47%; */
  justify-content: center;
  margin-left: 28%;
  align-items: center;
}

buutton {
  border: none;
  display: block;
  position: relative;
  padding: 0.7em 2.4em;
  font-size: 18px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: #0c3f69;
  z-index: 1;
  font-family: inherit;
  font-weight: 500;
 }
 
 buutton span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
  border: 3px solid #094e70;
 }
 
 buutton span::before {
  content: "";
  display: block;
  position: absolute;
  width: 8%;
  height: 500%;
  background: var(--lightgray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-60deg);
  transition: all 0.3s;
 }
 
 buutton:hover span::before {
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 100%;
  background: #01a1b3;
 }
 
 buutton:hover {
  color: white;
 }
 
 buutton:active span::before {
  background: #007bbd;
 }

 .outer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 38.2%;
 }

 .otp-card{
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1300' height='700' preserveAspectRatio='none' viewBox='0 0 1300 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1247%26quot%3b)' fill='none'%3e%3crect width='1300' height='700' x='0' y='0' fill='rgba(149%2c 221%2c 227%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c454.024C114.214%2c455.367%2c230.798%2c570.04%2c330.327%2c513.998C428.525%2c458.705%2c396.858%2c305.115%2c445.226%2c203.328C494.673%2c99.27%2c614.642%2c26.521%2c616.709%2c-88.669C618.821%2c-206.377%2c533.812%2c-306.781%2c455.965%2c-395.096C378.612%2c-482.851%2c290.345%2c-583.467%2c173.63%2c-591.33C58.347%2c-599.097%2c-20.356%2c-477.524%2c-127.554%2c-434.409C-220.383%2c-397.073%2c-339.062%2c-425.668%2c-411.047%2c-356.174C-484.068%2c-285.68%2c-501.661%2c-173.764%2c-502.669%2c-72.273C-503.603%2c21.713%2c-444.014%2c100.332%2c-416.475%2c190.198C-383.683%2c297.206%2c-419.918%2c446.396%2c-325.372%2c506.289C-230.658%2c566.288%2c-112.111%2c452.706%2c0%2c454.024' fill='%2374d3da'%3e%3c/path%3e%3cpath d='M1300 1204.289C1394.007 1206.459 1472.917 1142.87 1553.868 1095.026 1637.962 1045.324 1742.321 1009.373 1781.325 919.814 1820.234 830.4739999999999 1772.671 730.382 1754.358 634.673 1736.939 543.634 1733.105 447.131 1676.7939999999999 373.506 1618.843 297.737 1530.022 254.80399999999997 1439.904 223.531 1346.295 191.04700000000003 1248.712 177.69799999999998 1150.507 190.872 1039.933 205.70600000000002 908.725 213.80399999999997 842.5989999999999 303.66 776.334 393.706 825.119 520.0509999999999 825.991 631.848 826.723 725.651 805.092 822.967 847.302 906.74 888.884 989.267 976.328 1032.723 1053.552 1083.48 1131.585 1134.77 1206.645 1202.135 1300 1204.289' fill='%23b6e8ec'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1247'%3e%3crect width='1440' height='718' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}


.width-for-sml{
  width: 100%;
}

.width-sml{
  width: 65%;
}
.width-md{
  width: 70%;
}
.adjust{
  height: 17% !important;
  border-radius: 12px !important;

}

/* .page{
margin-top: 30%;
margin-left: 40%;
} */
.item	{
	background: #0064c9;
	display: grid;
	place-items: center;
	border-radius: 4px;
	transition: opacity 0.4s ease;
}

.clear-search-button {
  background-color: #e0f8ee !important;
  color: #3e4676 !important;
  margin-right: 0.5rem;
  border-color: #3B7DDD;
}

.clear-search-button:hover {
  background-color: #2469ce !important;
  color: #fff!important;
}

.clear-search-button:ina {
  background-color: #acf2d5 !important;
  color: #3e4676;
}

.export-button {
margin-bottom: 0.10rem !important;
}

/* .clear-search-button :hover {
  background-color: #acf2d5 !important;
  color: #3e4676;
} */

.loader {
	--color: #00b0c4;
	--size-mid: 6vmin;
	--size-dot: 1.5vmin;
	--size-bar: 0.4vmin;
	--size-square: 3vmin;
	
	display: block;
	position: relative;
	width: 50%;
	display: grid;
	place-items: center;
}

.loader::before,
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
}

/* .loader.--1::before {
	width: var(--size-mid);
	height: var(--size-mid);
	border: 4px solid var(--color);
	border-top-color: transparent;
	border-radius: 50%;
	animation: loader-1 1s linear infinite;
}

.loader.--1::after {
	width: calc(var(--size-mid) - 2px);
	height: calc(var(--size-mid) - 2px);
	border: 2px solid transparent;
	border-top-color: var(--color);
	border-radius: 50%;
	animation: loader-1 0.6s linear reverse infinite;
} */

.dropdown-options {
  border-color: #3f86fa !important;
  background-color: #ffffff !important;
  color: #3f86fa !important;
  margin-left: 0.3em !important;
}

.dropdown-options:hover {
  border-color: white !important;;
  background-color: #3f86fa !important;;
  color: white !important;;
}

@keyframes loader-1 {
	100% {
		transform: rotate(1turn);
	}
}

.info-table, td, th {  
  border: 1px solid #ddd;
  text-align: left;
}

.info-table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 15px;
}


.table-position {
  position: relative;
}

.table-header-position {
  position: absolute;
  top: -79px;
  right: 0px;

  
}

.dropdown-menu-up .dropdown-menu.show {
  display: block;
  right: auto;
  left: 0 !important;
  top: auto !important;
  bottom: 20px !important;
}

.center-align{

  align-items: center !important;
  justify-content: center !important;
  left: 10%;
}

.kyc-bg-color2 {
  background-color: #f7f7f7;
}

.fast-option {
  white-space: nowrap;
  /* line-height: 30px !important; */
}
