
.appt-trend-card{
    border-radius: 0.2rem;
    box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
    height: 70vh;
}
.appt-trend-graph{
    height: 360px;
}

.care-provider-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sign-in-card{
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    width: 1000px;
    height: 450px;
    
}

.img-1{
    content: '';
    position: absolute;
    margin-top: -12px;
    margin-bottom: -10px;
    left: 0;
    top: 0;
    width: 101.3%;
    height: 450px;
    display: inline-block;
}

.card-for-sign-in{
    height: 418px;
}
.only-card{
    margin-top: 40px;
}

.Section_top{
    background-image: url(img_1.jpg);
    overflow: hidden;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    justify-content: center;
    animation: change 25s infinite ease-in-out;
    margin-top: -12px;
    margin-bottom: -10px;
    width: 101.3%;
    height: 450px;
    display: inline-block;

}

.layer {
    background-color: #00b0af;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 40%;
}

.textt {

    margin-left:40px;
    width:60%;
    position: absolute;
    top: 40%;
    color: #fff;
    
}
.textt h1::after {
    content: "Referral Management";
    margin-left: 38px;
    width: 85%;
    position: absolute;
    top: -37%;
    color: #fff;
    left: -10%;
    animation: TextChange 25s infinite ease-in-out;
    
}
.textt p::after {
    content: " Equipo's Care Coordination Platform has been able to seamlessly overlay health home workflows and engage patients in improving their health. Our care managers appreciate the time saved from fewer calls and no-show visits.";
    text-align: justify;
    text-justify: inter-word;
    font-weight: 300;
    color: #fff;
    animation: TextParaChange 25s infinite ease-in-out;
}

.in-btn{
    margin-top: 10PX;
    WIDTH: 50%;
}


.newto{
    text-align: center;
    margin-bottom: -2px;
    position: relative;
    margin-top: -15px;
  }
.newto:before {
    content: "";
    display: block;
    width: 17%;
    height: 2px;
    background: #39ace9;
    left: 0;
    top: 50%;
    position: absolute;
}
  
.newto:after {
    content: "";
    display: block;
    width: 17%;
    height: 2px;
    background: #39ace9;
    right: 0;
    top: 50%;
    position: absolute;
  }
/* .CustomInput:: {
    color: #007BCF;
    border-color: #007BCF;
    background-color: #007BCF;
}

.CustomInput:disabled~.custom-control-label::before {
    color: #007BCF !important;
    border-color: #007BCF !important;
    background-color: #007BCF !important;
}
 */
@keyframes TextChange{
    0%
    {

        content: "Referral Management";
    }
    20%
    {
        content: "Care Delivery Redefined";
    }
    40%
    {
        content: "Referral Management";
    }
    60%
    {
        content: "Care Delivery Redefined";
    }        
    80%
    {
        content: "Referral Management";
    }
    100%
    {
        content: "Care Delivery Redefined";
    }
}

@keyframes TextParaChange{
    0%
    {

        content: " Equipo's Care Coordination Platform has been able to seamlessly overlay health home workflows and engage patients in improving their health. Our care managers appreciate the time saved from fewer calls and no-show visits.";
    }
    20%
    {
        content: " Equipo’s vision is to simplify care by empowering care teams with a care coordination platform that is built on the central tenets of risk stratification, needs assessment, care plan design, patient engagement and outcomes measurement.";
    }
    40%
    {
        content: " Equipo's Care Coordination Platform has been able to seamlessly overlay health home workflows and engage patients in improving their health. Our care managers appreciate the time saved from fewer calls and no-show visits.";
    }
    60%
    {
        content: " Equipo’s vision is to simplify care by empowering care teams with a care coordination platform that is built on the central tenets of risk stratification, needs assessment, care plan design, patient engagement and outcomes measurement.";
    }        
    80%
    {
        content: " Equipo's Care Coordination Platform has been able to seamlessly overlay health home workflows and engage patients in improving their health. Our care managers appreciate the time saved from fewer calls and no-show visits.";
    }
    100%
    {
        content: " Equipo’s vision is to simplify care by empowering care teams with a care coordination platform that is built on the central tenets of risk stratification, needs assessment, care plan design, patient engagement and outcomes measurement.";
    }
}

@keyframes change{
    0%
    {
        background-image: url(img_1.jpg);
        
    }
    20%
    {
        background-image: url(img_2.jpg);
    }
    40%
    {
        background-image: url(img_1.jpg);
    }
    60%
    {
        background-image: url(img_2.jpg);    }
    80%
    {
        background-image: url(img_1.jpg);
    }
    100%
    {
        background-image: url(img_2.jpg);    }
}

.donut-card-patient{
    margin-top: 53px;
}
.referral-card{
    margin-top: 53px;
}

.chartInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: calc(100px / 2);
    right: 20%;
    bottom: 20%;
    left: 79px;
    align-items: center;
    z-index: 1;
    
}

.chartDaysLabel {
  font-size: 0.75em;
  font-weight: 490;
}

.bar-chart-referral{
    min-height: 231px;

}
.bar-chart-appts{
    min-height: 231px;
}

.bar-card-patient{
    min-width: 123% !important;
    margin-left: -24%;
    margin-top: 6.5%;
    height: 82%;

}
.subscribe {
    position: absolute;
    height: 140px;
    width: 400px;
    padding: 20px;
    background-color: #FFF;
    border-radius: 4px;
    color: #333;
    box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
  }
  
  .subscribe:after {
    position: absolute;
    content: "";
    right: -10px;
    bottom: 18px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #1a044e;
  }
  
  .subscribe p {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 4px;
    line-height: 28px;
  }
  
  .subscribe input {
    position: absolute;
    bottom: 30px;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    padding: 0;
    width: 82%;
    background: transparent;
    transition: all .25s ease;
  }
  
  .subscribe input:focus {
    outline: none;
    border-bottom: 1px solid #0d095e;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', 'sans-serif';
  }
  
  .subscribe .submit-btn {
    position: absolute;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: #0f0092;
    color: #FFF;
    padding: 12px 25px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 5px;
    right: -10px;
    bottom: -20px;
    cursor: pointer;
    transition: all .25s ease;
    box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.4);
  }
  
  .subscribe .submit-btn:hover {
    background-color: #07013d;
    box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
  }

  .modal-content-api{
    display: flex;
    justify-content: center;
  }