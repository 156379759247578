.sidebar.toggled {
    margin-left: 0px;
    min-width: 75px;
    max-width: 75px;
    height: 1151px;
    overflow: auto;
}

@media(min-width:1500px){
    .sidebar.toggled {
        height: 1221px;
    }
}

@media(min-width:1380px){
    .sidebar.toggled {
        height: 1257px;
    }
}

@media(min-width:1260px){
    .sidebar.toggled {
        height: 1301px;
    }
}
@media(min-width:992px){
    .sidebar.toggled {
        height: auto;
    }
}


@media(min-width:1025px){
    .sidebar.toggled::-webkit-scrollbar{
        width: 0px;
        height: 6px;
    }

    .sidebar-brand img{
        width: auto;
        height: 43px;
    }
    .sidebar.toggled .version-number{
        font-size: 9px;
        }
    .sidebar.toggled .sidebar-item span{
        display: none;
    }
    .sidebar.toggled .sidebar-nav{
        padding-top: 0px;
    }
    .sidebar.toggled .powered-by span{
        display: none;
    }
    .sidebar.toggled .sidebar-item{
        text-align: center;
    }
    .sidebar{
        transition: all 0.3s;
    }
    .sidebar .sidebar_title{
        position: absolute;
        transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
    }
    .sidebar.toggled .equipo-logo{
        width: 50%;
    }
}

.navbar-color{
    color: black !important;
}
.side-bar-text{
    color: whitesmoke;
} 
a.side-bar-active-link{   
    background-color:whitesmoke;
}
a.side-bar-active-link .side-bar-text{   
    color: #000000;
}
a.side-bar-active-link .side-bar-active-link svg .side-bar-active-link .fas, .side-bar-active-link .far, .side-bar-active-link .fal, .side-bar-active-link .fab, a.side-bar-active-link svg, a.side-bar-active-link .fas, a.side-bar-active-link .far, a.side-bar-active-link .fal, a.side-bar-active-link .fab{   
    color: #153d77;
}

.sidebar-item:hover .side-bar-text{
    color: #000000;
}

.setting-btn{
    color: #007BCF !important ;
    
}
.setting-btn:hover{
    
    color: #333945 !important;
}