.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999 !important;
    display: table;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(0, 199, 137, 0.05);
    border-top-color: #00c789;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    z-index: 9999;
  }

  .busy
{
    position: absolute;
    left: 50%;
    top: 35%;
    display: none;
    /* background: transparent url("../images/loading-big.gif"); */
    z-index: 1000;
    height: 31px;
    width: 31px;
}

.busy-holder
{
    background: transparent;
    width: 100%;
    height: 100%;        
}

.overlay-z-index{
    z-index: 99999 !important
  }

.payment-config-header{
  color: #143A45
}
  .appointment-list-table div table tbody tr td {
    cursor: pointer;
  }

  .appointment-list-table div table tbody tr td .row-expansion-style {
    cursor: default;
  }
  .payment-config-tab{
    color: #1c1c1c;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .settings-tabs .nav-tabs .nav-link- {
  
    background-color: #9daea7;
    border-radius: 5px;
  }
  
  .settings-tabs .nav-tabs .nav-link.active {
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  .settings-tabs .nav-tabs .nav-link {
    cursor: pointer;
  }
.opacity-zero {
  opacity: 0;
}

.FA-label{
  max-width: 57px;
  white-space: normal;
  
}

.fa-btn{
  height: 3em;
  min-width: 70px;
  font-size: 1em;
  padding: 0.5em;
}

.admin-users-list {
  margin-top: -2em;
}

.admin-users-list tbody {
  display: block;
  height: 32.5vh;
  overflow-y: scroll;
}

.admin-users-list thead {
  display: table;
  width: calc(100% - 6px);
  table-layout: fixed;
}
@-moz-document url-prefix() {
  .admin-users-list thead{
      width: calc(100% - 18px);
  }
}
@supports (-moz-osx-font-smoothing: auto) {
  .admin-users-list thead{
      width: calc(100% - 6px);
  }
}

.admin-users-list  tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}



.badge-third {
  color: #fff;
  background-color: #383838; 
}

.reset-btn-enc{
  margin-left: 8px;
  width: 76px;
  height: 2.1em;
  background-color: #6b757c !important;
  border: none;
}

.CardForDecryption{
  height: auto;
}

@media screen and (max-width: 1280px) {
  .CardForDecryption {
    height: auto;
  }
}

@media screen and (width: 1440px) {
  .CardForDecryption {
    height: auto;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1276px)  {
  .CardForDecryption {
    height: auto;
  }
}


.btn-dec{
  position: relative;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.btn-dec button {
  width: 83px;
}


.result-card{
  box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.49);
  -webkit-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.49);
}

.result{
  display: flex;
  margin-top: 50px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  width: 100%;
  transition: transform .2s;
  
}


.result:hover {
  -ms-transform: scale(1.8); /* IE 9 */
  -webkit-transform: scale(1.8); /* Safari 3-8 */
  transform: scale(1.8); 
}

.roles-list-card{
  border: 1px solid #ececec;
}

.css-cveggr-MuiListItemIcon-root{
  min-width: 20px !important;
  color: #3b3b3bfb !important;
  
}

.css-ptiqhd-MuiSvgIcon-root{
  width: 56% !important;
}

.role-card-header{
      justify-content: center;
      margin-top: -14px;
      text-align: center;
      padding-bottom: 0px;
      margin-top: -22px;

}

.alert-success{
  height: 10%;
  letter-spacing: 2px;
    border-radius: 20px;
    width: 50%;
    text-align: center;
    justify-content: center;
    display: revert;
    align-items: center;
    background-color: #c8eef1;
    padding: 10px;
    margin: auto;
    margin-bottom: 13.5px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.table{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  width: 100%;
  margin-top: 1em;
}

.heading-div{
  margin-bottom: -6px;
}

.alert{
  padding: 5px 1px !important;
  margin-left: 0 !important;
  color: black;
  margin: 0 !important;
  letter-spacing: 3.3px;
  font-weight: 500;
  width: auto;

}

.table .alert{
  letter-spacing: 0 !important;
}

.actions-edit-div {
  margin: 0.050rem;
  width:2.25rem;
  height: 2.25rem;
  padding:0.50rem;
  background-color:#0085CF;
  border-radius:0.15rem
}

.actions-options-div {
  margin: 0.050rem;
  width:2.25rem;
  height: 2.25rem;
  padding:0.50rem 0.90rem;
  background-color:#0085CF;
  border-radius:0.15rem;
  position: relative;
  display: inline-block;

}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right:0
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f2f6f7}

.actions-options-div:hover .dropdown-content {
  display: block;
}
.edit-icon {
  color:#3B7DDD;
  cursor: pointer;
}
.edit-icon:hover :active{
  color:#003d96;
  cursor: pointer;
}

.search-clear-export{
  margin-right: 77px;
}
.options-icon {
  color:#3B7DDD;
  cursor: pointer;
}
.options-icon :hover :active{
  color:#003d96;
  cursor: pointer;
}

/* .dropdown-menu.show {
  display: block;
  right: 0;
  left: auto !important;
  top: 100% !important;
} */

.options-div {
  width: 0rem !important ;
  border-radius: 0.005rem !important;
  background-color:transparent !important ;
  border: none !important;
  font-weight: normal !important;
  display: inline !important;
  
 
}
/* .options-div-edit {
  width: 1.25rem !important ;
  border-radius: 0.005rem !important;
 
} */



@media screen and (min-width: 1280px) and (max-width: 1295px) {
  .search-clear-export {
    margin-right: 48px;
  }
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root{
  background-color: #383838;
  /* display: none !important; */
}

.itemed{
  background-color: #383838 !important;
}


.warning-label {
  display: block;
  color: #ab000d;
  font-size: 0.9em;
  white-space: nowrap;
  margin-top: 12px;
  margin-bottom: -3px;
}

.warning-text {
  color: rgb(68, 68, 68);

}

.info-button{
  margin-left: 11px ;
  margin-top: 32px ;
  width: 55px ;
  border: none;
  background-color: #ffa600;
}

.info-button:hover {
  margin-left: 11px ;
  margin-top: 32px ;
  width: 55px ;
  border: none;
  background-color: #cf7b36e3;
}

.info-button:focus {
  margin-left: 11px ;
  margin-top: 32px ;
  width: 55px ;

  background-color: #da8036;
  box-shadow: 0 0 0 0.1rem #FFC000;
}
.custom-modal-footer {
  justify-content: center !important;
}
.rpm-error-table {
  margin-top: 0.1em;
}

.rpm-error-table tbody {
  display: block;
  height: 55.5vh;
  overflow-y: scroll;
}

.rpm-error-table thead {
  display: table;
  width: calc(100% - 6px);
  table-layout: fixed;
}
@-moz-document url-prefix() {
  .rpm-error-table thead{
      width: calc(100% - 18px);
  }
}
@supports (-moz-osx-font-smoothing: auto) {
  .rpm-error-table thead{
      width: calc(100% - 6px);
  }
}

.rpm-error-table  tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.referral-add-btn{
  background-color: rgb(7, 152, 209) !important;
  border-color: rgb(34, 184, 243);
  width: 150px;
}

.referral-assignee-tag-add-btn{
  background-color: rgb(7, 152, 209) !important;
  border-color: rgb(34, 184, 243);
  width: 200px;
}

.referral-sender-fulfiller-add-btn{
  background-color: rgb(7, 152, 209) !important;
  border-color: rgb(34, 184, 243);
  width: 90px;
}

.referral-sender-fulfiller-back-btn{
  border-color: grey !important;
  background-color: grey;
  width: 90px;
}

.assignee-mapping .react-bootstrap-table{
  height: 400px !important;
    min-height: 400px !important;
    overflow: auto;
}