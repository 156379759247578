.margin-top {
    margin-top: 1rem;
}

.kyc-bg-color {
    background-color: #e8e8e8;
}

.kyc-bg-color2 {
    background-color: #f7f7f7;
}

.verify-document-row {
    justify-content: center;
}

.approve-all-document {
    float: right;
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
    /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
    scrollbar-width: none !important;
    /* Firefox */
    -ms-overflow-style: none !important;
    /* IE 10+ */
}

.table-bg-color {
    background-color: white;
}

.proof-of-identity-actions {
    margin: 0.25em;
    background-color: #e8e8e8;
    border: none;
    width: 80px;
    color: #3e4676;
}

.proof-of-identity-actions-container {
    margin-left: 0.75em;
}

.verify-physician-container {
    position: sticky;
    bottom: -1.4em;
    padding: 0.5em 0;
    background-color: white;
}

.selfie-with-proof-of-id {
    border-radius: 0.5em;
}

.selfie-with-proof-of-id-data {
    margin: 0.33em 1em;
}

.selfie-image-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selfie-image-card img {
    max-width: 100%;
    height: 15vh;
    background-repeat: no-repeat;
    background-size: contain;
}

.proof-of-identity-action-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0.7em;
}

.selfie-action-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0.7em;
}

.id-proof-header {
    font-size: 1.1em;
}

.image-viewer {
    width: 100%;
    height: auto;
}

.pdf-viewer {
    width: 100%;
    height: 78vh;
    overflow-y: scroll;
}

.doc-viewer-blank-state {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.doc-viewer-blank-state {
    text-align: center;
    margin-top: 15vh;
    width: 100%;
}

.reject-reason {
    min-height: 10vh;
    max-height: 20vh;
}

.status-badge {
    font-size: 1em;
    min-width: 6em;
    max-width: 10em;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.top-download-button {
    margin: auto;
}

.list-group-item.active {
    z-index: 0 !important;
    background-color: #00ca8c !important;
    border-color: #00ca8c !important;
}

.verify-button {
    width: 145px;
}

.input-height {
    height: 2.5em;
}

.doc-upload-margin-top {
    margin-top: 1.5em;
}

.doc-upload-modal-height {
    min-height: 300px;
    max-height: 75vh;
    overflow-y: scroll;
}

.margin-left {
    margin-left: 0.5em;
}

.drop-zone-area {
    border-style: dashed;
    border-width: thin;
    border-color: lightgrey;
    border-radius: 0.5em;
    height: 11vh;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-zone-file {
    /* padding: 1em 0; */
    padding-top: 1em;
}

.green-shadow {
    -webkit-box-shadow: 0px 6px 21px 0px rgba(148, 255, 169, 1);
    -moz-box-shadow: 0px 6px 21px 0px rgba(148, 255, 169, 1);
    box-shadow: 0px 6px 21px 0px rgba(148, 255, 169, 1);
    transition: 500ms ease;
}

.upload-doc-button-width {
    min-width: 9.3em;
}

.doc-upload-tooltip {
    max-width: 25vw;
    font-size: 1em;
    font-weight: 500;
    border-radius: 0.25em;
    z-index: 100;
}

.selfie-rejection-comment {
    display: inline-block;
    width: 35vw;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.file-name {
    font-weight: 550;
    color: gray;
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.identity-file-upload {
    background-color: #00c789;
    color: white;
    border-radius: 5px;
    display: inline-block;
    padding: 7px 11px;
    cursor: pointer;
}

.identity-file-upload:hover {
    background-color: #00a94e;
    transition: 200ms ease;
}